<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Guías</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Comercialización</li>
                  <li class="breadcrumb-item active">Administración</li>
                  <li class="breadcrumb-item active">Gestión Clientes</li>
                  <li class="breadcrumb-item active">Guías</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div>
                <div class="card-body">
                  <div class="row">
                    <div class="form-group col-md-1">
                      <label>N° Viaje</label>
                      <input
                        type="number"
                        v-model="filtros.id"
                        placeholder="N° viaje"
                        label="id"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>N° Guia</label>
                      <input
                        type="number"
                        v-model="filtros.guia_id"
                        placeholder="N° guia"
                        label="guia_id"
                        class="form-control form-control-sm"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Vehículo</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.vehiculo_id"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Remolque</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Placa"
                        v-model="filtros.remolque_id"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <label>Doc. Conductor</label>
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        placeholder="Documento"
                        v-model="filtros.conductor_id"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Nombre Conductor</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Nombre Conductor"
                        v-model="filtros.conductor_nombre"
                        @keyup.enter="getIndex()"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Producto</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="producto"
                        placeholder="Nombre"
                        label="nombre"
                        :options="listasForms.productos"
                        @input="seleccionarProducto()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Transportadora</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="transportadora"
                        placeholder="Razon social"
                        label="razon_social"
                        :options="listasForms.empresas"
                        @input="seleccionarTransportadora()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Sitio Cargue</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="sitio_cargue"
                        placeholder="Sitio Cargue"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="seleccionarSitioCargue()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Sitio Descargue</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Nombre"
                        v-model="filtros.sitio_descargue_id"
                      />
                    </div>
                    <div class="form-group col-md-2">
                      <label>Estado</label>
                      <select
                        class="form-control form-control-sm"
                        v-model="filtros.estado"
                        @change="getIndex()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="estado in listasForms.estados"
                          :key="estado.numeracion"
                          :value="estado.numeracion"
                        >
                          {{ estado.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group col-md-2">
                      <label>Último Punto</label>
                      <v-select
                        :class="
                          $store.getters.getDarkMode ? 'dark-vselect' : ''
                        "
                        v-model="ultimo_punto"
                        placeholder="Sitio"
                        label="nombre"
                        :options="listasForms.sitios"
                        @input="seleccionarSitio()"
                      ></v-select>
                    </div>
                    <div class="form-group col-md-4">
                      <label>Rango de fechas del viaje</label>
                      <div class="row">
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_inicio"
                            @input="validaFechas()"
                          />
                        </div>
                        <div class="col-md-6">
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            v-model="filtros.fecha_fin"
                            @input="validaFechas()"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                        <label for="fecha_expedicion">Fecha Expedición</label>
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_expedicion"
                        />
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn bg-navy mt-4"
                          @click="getIndex()"
                        >
                          <i class="fas fa-search"></i><br />Buscar
                        </button>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-danger mt-4"
                          @click="limpiar()"
                        >
                          <i class="fas fa-broom"></i><br />Limpiar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <table
                          style="font-size: 9px; overflow-x: auto"
                          id="viajes"
                          class="table table-bordered table-striped table-hover table-sm team-roster-table table-responsive"
                        >
                          <thead class="thead">
                            <tr>
                              <th></th>
                              <th># Viaje</th>
                              <th>Guía</th>
                              <th>Vehículo</th>
                              <th>Remolque</th>
                              <th>Conductor</th>
                              <th>Transportadora</th>
                              <th>Producto</th>
                              <th>Sitio cargue</th>
                              <th>Sitio descargue</th>
                              <th>Último punto</th>
                              <th>Cita Cargue</th>
                              <th>Fecha Expedición</th>
                              <th>Fecha Salida</th>
                              <th>Estado</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in guiasTerceros.data"
                              :key="item.id"
                            >
                              <td>
                                <!-- Split dropright button -->
                                <div
                                  :class="
                                    guiasTerceros.data.length == 1
                                      ? 'dropop'
                                      : 'dropright'
                                  "
                                  style="position: static; z-index: auth"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-dark btn-sm"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i class="fas fa-align-justify"></i>
                                  </button>
                                  <div class="dropdown-menu">
                                    <div v-if="item.guia">
                                      <a
                                        id="botonImp"
                                        type="button"
                                        class="dropdown-item"
                                        style="cursor: pointer"
                                        @click="printGuiaRegalias(item.id)"
                                        v-if="
                                          $store.getters.can(
                                            'com.guias.actualizarGuia'
                                          ) &&
                                            item.guia !== null &&
                                            item.guia !== undefined
                                        "
                                      >
                                        <i class="fas fa-print"></i>
                                        Imprimir Guía
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-sm btn-default py-0"
                                  data-toggle="modal"
                                  data-target="#modal-form-detViaje"
                                  @click="
                                    llenarModalDetViaje(
                                      item.id,
                                      item.tipo_operacion
                                    )
                                  "
                                >
                                  {{ item.id }}
                                </button>
                              </td>
                              <td
                                v-if="
                                  item.guia !== null && item.guia !== undefined
                                "
                              >
                                {{ item.guia.numero }}-{{
                                  item.guia.digito_verificacion
                                }}
                              </td>
                              <td v-else class="text-center">
                                <span class="badge badge-danger"
                                  >Sin asignar</span
                                >
                              </td>
                              <td>{{ item.vehiculo }}</td>
                              <td>
                                {{ item.remolque ? item.remolque : "" }}
                              </td>
                              <td>
                                <div>
                                  {{ item.conductor }}
                                </div>
                                <span class="badge badge-info">
                                  {{ item.documento_conductor }}
                                </span>
                              </td>
                              <td>
                                {{
                                  item.transportadora
                                    ? item.transportadora.razon_social
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  item.producto_liquido
                                    ? item.producto_liquido.nombre
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  item.sitio_origen
                                    ? item.sitio_origen.nombre
                                    : ""
                                }}
                              </td>
                              <td>
                                {{
                                  item.sitio_destino ? item.sitio_destino : ""
                                }}
                              </td>
                              <td>
                                {{ item.sitio ? item.sitio.nombre : "" }}
                              </td>
                              <td>
                                {{ item.cita_cargue ? item.cita_cargue : "" }}
                              </td>
                              <td class="text-center">
                                {{ item.guia ? item.fecha_expedicion : "" }}
                              </td>
                              <td class="text-center">
                                {{ item.guia ? item.fecha_cargue : "" }}
                                {{ item.guia ? item.hora_cargue : "" }}
                              </td>
                              <td class="text-center">
                                <span
                                  class="badge"
                                  :class="
                                    item.estado == 1 || item.estado == 7
                                      ? 'badge-primary'
                                      : item.estado == 6 || item.estado == 9
                                      ? 'bg-navy'
                                      : item.estado == 5 || item.estado == 10
                                      ? 'badge-info'
                                      : item.estado == 4 || item.estado == 2
                                      ? 'badge-success'
                                      : item.estado == 3
                                      ? 'badge-danger'
                                      : 'badge-dark'
                                  "
                                >
                                  {{ item.estado }}. {{ item.estadoViaje }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="guiasTerceros.total">
                    <p>
                      Mostrando del <b>{{ guiasTerceros.from }}</b> al
                      <b>{{ guiasTerceros.to }}</b> de un total:
                      <b>{{ guiasTerceros.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="guiasTerceros"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ViajesDetViaje ref="ViajesDetViaje" />
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import axios from "axios";
import Loading from "../../../../components/Loading";
import ViajesDetViaje from "../../../com/comercio/viajes/ViajesDetViaje";
export default {
  name: "GuiaTerceroIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    ViajesDetViaje,
  },
  data() {
    return {
      cargando: false,
      guiasTerceros: {},
      filtros: {},
      tipo_combustible: null,
      transportadora: null,
      producto: null,
      sitio_cargue: null,
      sitio_descargue: null,
      ultimo_punto: null,
      listasForms: {
        estados: [],
        sitios: [],
        productos: [],
        tiposCombustible: [],
        empresas: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/com/guiasTerceros?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.guiasTerceros = response.data;
        });
    },

    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    limpiar() {
      this.filtros.id = "";
      this.filtros.guia_id = "";
      this.filtros.vehiculo_id = "";
      this.filtros.remolque_id = "";
      this.filtros.conductor_id = "";
      this.filtros.producto_id = "";
      this.filtros.transportadora_id = "";
      this.filtros.sitio_cargue_id = "";
      this.filtros.sitio_descargue_id = "";
      this.filtros.estado = "";
      this.filtros.fecha_inicio = "";
      this.filtros.fecha_fin = "";
      this.filtros.fecha_expedicion = "";
      this.transportadora = null;
      this.producto = null;
      this.sitio_cargue = null;
      this.sitio_descargue = null;
      this.ultimo_punto = null;
      this.getIndex();
    },

    getTiposCombustibles() {
      axios.get("/api/lista/24").then((response) => {
        this.listasForms.tiposCombustible = response.data;
      });
    },

    buscarProductos() {
      let me = this;
      var url = "api/hidrocarburos/Productos/lista";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.productos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url)
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.transportadora_id = this.transportadora.id;
      }
    },

    seleccionarProducto() {
      if (this.producto != null) {
        this.filtros.producto_id = this.producto.id;
      }
    },

    seleccionarSitioCargue() {
      if (this.sitio_cargue != null) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      }
    },

    seleccionarSitio() {
      if (this.ultimo_punto != null) {
        this.filtros.sitio_id = this.ultimo_punto.id;
      }
    },

    getEstados() {
      axios.get("/api/lista/39").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    llenarModalDetViaje(viaje, tipo_control) {
      this.$refs.ViajesDetViaje.llenar_modal_detViaje(viaje, tipo_control);
    },

    llenarModalMapa(item, fecha) {
      this.$refs.VentanaOperacionesMapa.llenar_modal_mapa(item, fecha);
    },

    verDetGuiaRegalias(detGuia) {
      return this.$router.push({
        name: "/Com/DetGuiasTercerosForm",
        params: {
          accion: "Show",
          actoGuia: 1,
          data_edit: detGuia,
        },
      });
    },

    printGuiaRegalias(idDetGuia) {
      window.open(
        process.env.VUE_APP_API_URL + "/toPrint/guiaRegalias/" + idDetGuia,
        "_blank"
      );
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTiposCombustibles();
    this.buscarTransportadora();
    this.buscarProductos();
    this.buscarSitios();
  },
};
</script>
